<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Event Discount
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Master</li>          
          <li class="breadcrumb-item active" aria-current="page">Event Discount</li>
        </ol>
      </nav>
    </div>
    <div class="row" ref="containerMain">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<b-modal
						:centered="true"
						:hide-footer="true"
						:title="titleModal"
						v-model="modalForm"
					>
						<b-row>
							<b-col class="mb-3" lg="12">
                <template v-if="stateCrud != 'upload'">
                  <b-row>
                    <b-col lg="6"><label>Name</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <input type="text" v-model="form.name" class="form-control form-control-lg" placeholder="Name">
                  <b-row>
                    <b-col lg="6"><label>Minimum Transaction</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <input type="number" v-model="form.minimum_transaction" class="form-control form-control-lg" placeholder="Minimum Transaction">                  
                  <div class="row">
                    <div class="col-lg-6">
                      <b-row class="mt-2">
                        <b-col lg="6"><label>Type</label></b-col>
                        <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                      </b-row>
                      <v-select v-model="optSelect2" :options="dataOption2"></v-select>
                    </div>
                    <div class="col-lg-6">
                      <b-row class="mt-2">
                        <b-col lg="6"><label>Parameter</label></b-col>
                        <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                      </b-row>
                      <v-select v-model="optSelect1" :options="dataOption1"></v-select>
                    </div>
                  </div>                                    
                  <div class="row">
                    <div class="col-lg-6" style="padding-top: 18px;">
                      <b-row>
                        <b-col lg="6"><label>Value</label></b-col>
                        <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                      </b-row>
                      <input type="number" v-model="form.discount" class="form-control form-control-lg" placeholder="Discount Value">
                    </div>
                    <div class="col-lg-6" style="padding-top: 18px;">
                      <b-row>
                        <b-col lg="6"><label>Valid Until</label></b-col>
                        <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                      </b-row>
                      <date-picker
                        :disabled-date="disabledBeforeToday"
                        :default-value="new Date()"                  
                        v-model="form.valid_until"
                        format="YYYY-MM-DD"
                        date-format="YYYY-MM-DD"
                        formatter="DD-MM-YYYY"
                        placeholder="Valid Until"
                        type="date"
                      ></date-picker>                      
                    </div>                    
                  </div>                
                  <b-row>
                    <b-col lg="6"><label>Term And Conditions</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <textarea v-model="form.term_conditions" class="form-control form-control-lg"></textarea>                
                  <b-row>
                    <b-col lg="6"><label>Remarks</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <textarea v-model="form.remarks" class="form-control form-control-lg"></textarea>
                </template>
                <template v-else>
                  <b-row>
                    <b-col lg="6"><label>Image</label></b-col>
                    <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
                  </b-row>
                  <input type="file" id="berkas" ref="berkas" v-on:change="handleFileUpload()"/>                  
                  <!-- <input type="number" v-model="form.minimum_transaction" class="form-control form-control-lg" placeholder="Minimum Transaction"> -->
                </template>
							</b-col> 
							<b-col class="mb-3 mb-xl-0" lg="12">
								<b-input-group>
									<b-button
										variant="primary"
										href="javascript:void(0)"
										@click="sendPayload()">Save
									</b-button>
								</b-input-group>
							</b-col>                
						</b-row>
					</b-modal>					
					<div class="card-body">
						<b-form-group
							label="Filter"
							label-for="filter-input"
							label-cols-md="3"
							label-align-md="right"
							label-size="md"
							class="mb-1"
						>
							<b-input-group size="md">
								<b-form-input
									id="filter-input"
									type="search"
									placeholder="Type to Search"
									style="margin-right: 0.5rem"
								></b-form-input>

								<b-input-group-append>
									<b-button
										style="margin-right: 0.5rem"
										>Search</b-button
									>
									<b-button >Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>							
					</div>
				</div>
      </div>

      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-head">
						<div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
							<button type="button"  
								class="btn btn-sm btn-primary btn-icon-text"
								@click="openAddModal()">
								Add
								<i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
							</button>							
						</div>
					</div>
					<div class="card-body">
							<b-table 
							responsive 
							show-empty
							:striped="tableConfig.striped"
							:bordered="tableConfig.bordered"
							:borderless="tableConfig.borderless"
							:outlined="tableConfig.outlined"
							:small="tableConfig.small"
							:hover="tableConfig.hover"
							:dark="tableConfig.dark"
							:fixed="tableConfig.fixed"
							:foot-clone="tableConfig.footClone"
							:no-border-collapse="tableConfig.noCollapse"
							:head-variant="tableConfig.headVariant"
							:table-variant="tableConfig.tableVariant"
							:items="data" 
							:fields="fields">
								<template #cell(index)="data">
									{{ data.index + 1 }}
								</template>
								<template v-slot:cell(valid_until)="row">
									{{ normalDateTime(row.item.valid_until) }}
								</template>                
								<template v-slot:cell(created_at)="row">
									{{ normalDateTime(row.item.created_at) }}
								</template>
								<template v-slot:cell(updated_at)="row">
									{{ normalDateTime(row.item.updated_at) }}
								</template>
								<template v-slot:cell(actions)="row">
									<button type="button"  
										class="btn btn-sm btn-success btn-icon-text"
										@click="openUploadModal(row.item.uuid, row.item)">
										<i class="mdi mdi mdi-upload btn-icon-append"></i>
                    Upload
									</button>
									<br>
									<br>

									<button type="button"  
										class="btn btn-sm btn-warning btn-icon-text"
										@click="openEditModal(row.item.uuid, row.item)">
										<i class="mdi mdi-file-check btn-icon-append"></i>
                    Edit
									</button>
									<br>
									<br>

									<button type="button"  
										class="btn btn-sm btn-danger btn-icon-text"
										@click="openDeleteModal(row.item.uuid, row.item)">
										<i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
                    Delete                    
									</button>
									<br>
									<br>                  
								</template>							
							</b-table>
							<b-pagination
								v-model="page"
								:total-rows="totalRows"
								:per-page="limit"
								align="fill"
								size="md"
								class="mb-1"
								v-on:click.native="callback"
							></b-pagination>
              <b-row class="pt-4">
                <p class="col-lg-12 mb-1">Total Data : {{ data.length }}</p>                
                <p class="col-lg-12 mb-1">Total Data per Page : {{ totalRows }}</p>              
                <p class="col-lg-12 mb-1">Total Page : {{ lastPage }}</p>
              </b-row>              
					</div>
				</div>
      </div>			
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      data: [],
      dataOption1: [],
      optSelect1: '',
      dataOption2: [],
      optSelect2: '',            
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      formUpload: {

      },
      form: {
				name: "",
        minimum_transaction : 0,
        valid_until : "",
        discount: 0,
        term_conditions : "",
        remarks: ""
      },						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'discount_type_name', label: 'Type', class: "text-center", sortable: true},
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
				{key: 'discount_parameters_name', label: 'Parameter', class: "text-center", sortable: true},                
				{key: 'minimum_transaction', label: 'Minimum Transaction', class: "text-center", sortable: true},
				{key: 'valid_until', label: 'Valid Until', class: "text-center", sortable: true},                
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
		responseAxiosError: (error) => packageGlobals.responseAxiosError(error),    
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today 
    },        
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },		
    getAll: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/event-discount?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },
    getDiscountType: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/discount-type?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)      
			.then((response) => {
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption2.push(pushData)
        });
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },
    getDiscountParameter: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/discount-parameter?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      });
    },    
		handleFileUpload(){			
			this.formUpload.image = this.$refs.berkas.files[0];			
		},    
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openAddModal: function () {
      this.form.name = ""
      this.form.remarks = ""      
      this.titleModal = "Add Event Discount"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Event Discount"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.form.remarks = item.remarks      
      this.modalForm = true
    },
    openUploadModal: function (id,item) {
      this.titleModal = "Upload Event Discount Image"
      this.stateCrud = "upload"
      this.idKey = id
      this.form.name = item.name
      this.form.remarks = item.remarks      
      this.modalForm = true      
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        })
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/master/event-discount/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          });
        }
      })
    },		
    sendPayload: function () {
      if (this.stateCrud != "upload") {
        let checkField = packageGlobals.checkField(
          [
            {value : this.form.name, message : 'field name is required', key:'name'}        
          ]
        )
        var flag = checkField.flag, message = checkField.message
        if (flag == 0) {
          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.containerMain,
            canCancel: false,
            onCancel: this.onCancel,
          })

          this.form.uuid_discount_parameters = this.optSelect1['id']
          this.form.uuid_discount_types = this.optSelect2['id']                    
          if (this.stateCrud == "add") {
            this.created_by = localStorage.getItem("uuid")
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
            // axios.post(`${packageStore.mainApi}/api/web/master/event-discount`,this.form,packageGlobals.configAxios)					
            axios.post(`${packageStore.mainApi}/api/web/master/event-discount`,this.form)
            .then((response) => {
              this.responseAxios(response)
              loader.hide()
            })				
            .catch(function (error) {
              this.responseAxiosError(error)
              loader.hide();        
            });
          } else if (this.stateCrud == "edit") {
            this.updated_by = localStorage.getItem("uuid")					
            packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
            axios.put(`${packageStore.mainApi}/api/web/master/event-discount/${this.idKey}`,this.form)
            .then((response) => {
              this.responseAxios(response)
              loader.hide()
            })				
            .catch(function (error) {
              this.responseAxiosError(error)
              loader.hide();        
            });
          } 
          // loader.hide()
        }
        else
        {
          this.$swal.fire({
            title: "Ups, There is a mistake",
            text: message,
            icon: "error",
            confirmButtonText: "Ok",
          });        
        }        
      }
      else
      {
        if (this.stateCrud == "upload") {
          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.containerMain,
            canCancel: false,
            onCancel: this.onCancel,
          })

          this.updated_by = localStorage.getItem("uuid")
          let formData = new FormData();
          formData.append('file', this.formUpload.image);          
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          // this.configAxios.headers["Content-Type"] = "multipart/form-data";
          axios.put(`${packageStore.mainApi}/api/web/master/event-discount/upload/${this.idKey}/${localStorage.getItem("uuid")}`,formData)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          });
        }
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)
    this.getDiscountParameter('-',1,999)
    this.getDiscountType('-',1,999)        
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>